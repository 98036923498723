import React, { useLayoutEffect, useState } from 'react'
import '@src/sharedBetweenApps/lib/polyfills'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { BetterFocusContextProvider } from '@cbc/hbbtv-react-better-focus'
import { CONSENT_COOKIE_NAME } from '@cbc/hbbtv-consent-cookie-utility'
import { ThemeProvider } from 'styled-components'
import { useRouter } from 'next/router'
import theme from '../themes/default'
import GlobalStyle from '../themes/GlobalStyle'
import { HistoryContextProvider } from '@lib/context/HistoryContextProvider'
import GlobalHotKeys from '@src/sharedBetweenApps/lib/utils/GlobalHotKeys'
import {
  getConsent,
  setLocalStorage,
} from '@src/sharedBetweenApps/lib/utils/consent'
import useInitializeOipfApplication from '@src/sharedBetweenApps/lib/utils/useInitializeOipfApplication'
import PlayerConfigurationProvider from '@src/sharedBetweenApps/lib/playout/PlayerConfigurationProvider'
import BugsnagErrorBoundary from '@src/sharedBetweenApps/lib/bugsnag/BugsnagErrorBoundary'
import { BugsnagBoundary } from '@src/sharedBetweenApps/lib/bugsnag/bugsnag'
import WaitForRouter from '@components/shared/WaitForRouter'

function TVHighlights({ Component, pageProps }: AppProps) {
  const [initialized, setInitialized] = useState(false)
  useInitializeOipfApplication(setInitialized)

  if (typeof window === 'undefined') {
    React.useLayoutEffect = React.useEffect
  }

  useLayoutEffect(() => {
    const consent = getConsent()
    if (consent) {
      setLocalStorage(CONSENT_COOKIE_NAME, consent)
    }
  }, [])

  const router = useRouter()
  const deeplink =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search).get('deep')
      : null
  if (deeplink) {
    router.replace(deeplink)
    return <></>
  }

  return (
    <BugsnagErrorBoundary Boundary={BugsnagBoundary}>
      <PlayerConfigurationProvider>
        <HistoryContextProvider>
          <BetterFocusContextProvider>
            <Head>
              <meta
                httpEquiv="content-type"
                content="application/vnd.hbbtv.xhtml+xml"
              />
            </Head>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <WaitForRouter>
                <Component {...pageProps} initialized={initialized} />
              </WaitForRouter>
            </ThemeProvider>
            <GlobalHotKeys />
          </BetterFocusContextProvider>
        </HistoryContextProvider>
      </PlayerConfigurationProvider>
    </BugsnagErrorBoundary>
  )
}
export default TVHighlights
