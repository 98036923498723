import { useCallback } from 'react'
import { Keys, useHotKeys } from '@cbc/hbbtv-captain-hook'
import { useRouter } from 'next/router'
import { openDataPrivacyCenter, openLaunchbar } from '../openApplication'
import { useCurrentStationInfo } from './currentStation'

const GlobalHotKeys = () => {
  const router = useRouter()
  const stationInfo = useCurrentStationInfo()

  const onRedButtonKeydown = useCallback(() => {
    openLaunchbar(stationInfo)
  }, [stationInfo])

  const onGreenButtonKeydown = useCallback(() => {
    openLaunchbar(stationInfo, {
      show: 'launchbar',
    })
  }, [stationInfo])

  const onBlueButtonKeydown = useCallback(() => {
    openDataPrivacyCenter(stationInfo, {
      referrer: 'tvhighlights-v4',
    })
  }, [stationInfo])

  const onYellowButtonKeydown = useCallback(() => {
    router.push('/100')
  }, [router])

  useHotKeys(
    {
      [Keys.BUTTON_RED]: onRedButtonKeydown,
      [Keys.BUTTON_GREEN]: onGreenButtonKeydown,
      [Keys.BUTTON_BLUE]: onBlueButtonKeydown,
      [Keys.BUTTON_YELLOW]: onYellowButtonKeydown,
    },
    { global: true },
  )

  return null
}

export default GlobalHotKeys
