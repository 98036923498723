import { useEffect } from 'react'
// @ts-ignore
import OipfApplication from '@cbc/hbbtv-react-oipf-application'
import {
  broadcastVisible,
  BROADCAST_ELEMENT_ID,
} from '@src/sharedBetweenApps/components/OipfApplication/broadcast'

let initialized = false
export function isInitialized() {
  return initialized
}

const useInitializeOipfApplication = (
  setInitialized: (initialized: boolean) => void,
) => {
  const isBrowser = typeof document !== 'undefined'
  useEffect(() => {
    if (!isBrowser) {
      return
    }

    try {
      const oipfApp = new OipfApplication(window, document, {
        videoElementId: BROADCAST_ELEMENT_ID,
        appMgrElementId: 'appmgr',
      })
      oipfApp.initApplication()
      oipfApp.initVideoplayer()
      oipfApp.registerKeys('ALL')
      ;(window as any).oipfApp = oipfApp
    } catch (e) {
      console.error('OIPF not supported', e)
    }

    setInitialized(true)
    initialized = true

    const appElement = document.getElementById('app')

    if (appElement) {
      appElement.style.zIndex = '50'
    }

    // show player after initializing to prevent "flickering" of the TV signal
    const player = document.getElementById(BROADCAST_ELEMENT_ID)

    if (player && broadcastVisible) {
      player.style.visibility = 'visible'
      try {
        ;(player as any).bindToCurrentChannel()
      } catch (e) {
        console.error('bindToCurrentChannel failed', e)
      }
    }
  }, [])
}

export default useInitializeOipfApplication
