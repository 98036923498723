// @ts-expect-error TS(7016): Could not find a declaration file for module '@cbc/hbbtv-referrer-app-utility'.
import { encodeToQueryString } from '@cbc/hbbtv-referrer-app-utility'
import getConfig from 'next/config'
import { stringifyUrl } from './utils/url/stringifyUrl'
import { BroadcastInfo } from '@src/sharedBetweenApps/lib/utils/station'

const { publicRuntimeConfig } = getConfig()

const LAUNCHBAR_APP_LABEL = 'launchbar'
const DATA_PRIVACY_CENTER_APP_LABEL = 'dsc'
const DIGITALTEXT_APP_LABEL = 'digitaltext'
const RTLPLUS_APP_LABEL = 'rtlplus'
const TVHIGHLIGHTS_APP_LABEL = 'tvhighlights'

type AppName =
  | typeof LAUNCHBAR_APP_LABEL
  | typeof DATA_PRIVACY_CENTER_APP_LABEL
  | typeof DIGITALTEXT_APP_LABEL
  | typeof RTLPLUS_APP_LABEL
  | typeof TVHIGHLIGHTS_APP_LABEL

type StationUrlsByCountry = Record<string, string>

interface StationAitEntry {
  dvb: StationUrlsByCountry
  http: StationUrlsByCountry
}

type AitConfiguration = Record<string, StationAitEntry>

const app2EnvVarMapping = {
  [LAUNCHBAR_APP_LABEL]:
    publicRuntimeConfig.processEnv.NEXT_PUBLIC_AIT_URLS_LAUNCHBAR,
  [DATA_PRIVACY_CENTER_APP_LABEL]:
    publicRuntimeConfig.processEnv.NEXT_PUBLIC_AIT_URLS_DSC,
  [DIGITALTEXT_APP_LABEL]:
    publicRuntimeConfig.processEnv.NEXT_PUBLIC_AIT_URLS_DIGITALTEXT,
  [RTLPLUS_APP_LABEL]:
    publicRuntimeConfig.processEnv.NEXT_PUBLIC_AIT_URLS_RTLPLUS,
  [TVHIGHLIGHTS_APP_LABEL]:
    publicRuntimeConfig.processEnv.NEXT_PUBLIC_AIT_URLS_TVHIGHLIGHTS,
}

function getAITURLs(
  app: AppName,
  station: string,
  country: string,
): { dvb: string; http: string } {
  const parsedConfig: AitConfiguration = JSON.parse(app2EnvVarMapping[app])

  return {
    dvb: parsedConfig[station].dvb[country],
    http: parsedConfig[station].http[country],
  }
}

function currentAppInfo(): string {
  const appInfo = {
    'TV Highlights Version':
      publicRuntimeConfig.processEnv.NEXT_PUBLIC_APP_VERSION ?? 'dev',
    'Release Stage': publicRuntimeConfig.processEnv.NEXT_PUBLIC_ENV ?? 'local',
  }

  return encodeURIComponent(JSON.stringify(appInfo))
}

function currentReferrerApp(): string {
  // TvHv4 does not use dvb aits. Deeplinking to any page is possible, so we can always use the current href as the return url.
  return encodeToQueryString({
    httpUrl: window.location.href,
    dvbUrl: '',
  })
}

function openApplicationByLabel(
  label: AppName,
  query: Record<string, any> = {},
  broadcastInfo: Required<BroadcastInfo>,
) {
  const { dvb, http } = getAITURLs(
    label,
    broadcastInfo.station,
    broadcastInfo.country,
  )

  window.oipfApp.openApplication(
    stringifyUrl(dvb, query),
    stringifyUrl(http, query),
  )
}

export function openLaunchbar(
  broadcastInfo: BroadcastInfo,
  query: Record<string, any> = {},
) {
  if (!broadcastInfo.station || !broadcastInfo.country) {
    return
  }
  openApplicationByLabel(
    LAUNCHBAR_APP_LABEL,
    query,
    broadcastInfo as Required<BroadcastInfo>,
  )
}

export function openDataPrivacyCenter(
  broadcastInfo: BroadcastInfo,
  query: Record<string, any> = {},
) {
  if (!broadcastInfo.station || !broadcastInfo.country) {
    return
  }
  openApplicationByLabel(
    DATA_PRIVACY_CENTER_APP_LABEL,
    {
      ...query,
      appinfo: currentAppInfo(),
      referrerApp: currentReferrerApp(),
    },
    broadcastInfo as Required<BroadcastInfo>,
  )
}
